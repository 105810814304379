import {FC, memo, ReactNode} from 'react';
import {Text} from '@chakra-ui/react';

interface IProps {
  value: string;
  isDisabled: boolean;
  render?: (value: string) => ReactNode;
}

const TableValueFC: FC<IProps> = ({value, isDisabled, render}) => {
  return (
    <Text
      fontSize="12px"
      color="bodyText"
      opacity={isDisabled ? '0.5' : '1'}
      textOverflow="ellipsis"
      whiteSpace="nowrap"
      overflow="hidden"
    >
      {render ? render(value) : value}
    </Text>
  );
};

export const TableValue = memo(TableValueFC);
