import {TableVirtuoso} from 'react-virtuoso';
import {Text, Flex, Center, useToken, Table, Tbody, Tr, Th, Td, Thead} from '@chakra-ui/react';
import {SystemStyleObject} from '@chakra-ui/styled-system';
import {forwardRef, useMemo} from 'react';

import {Checkbox} from '../../inputs';
import {ISimpleTableColumn, ISimpleTableRow} from '../../../interfaces';

import {TableValue} from './components';
import {TableEditableValue} from './components/TableEditableValue';

export enum TABLE_VARIANTS {
  FIRST_COLOMN_FIXED
}

interface IProps<T> {
  isCheckboxShown?: boolean;
  noItemsTitle?: string;
  columns: ISimpleTableColumn[];
  rows: ISimpleTableRow<T>[];
  tableSx?: SystemStyleObject;
  variant?: TABLE_VARIANTS;
  onSelect?: (value: T, isSelected: boolean) => void;
  onSelectAll?: () => void;
  onUnselectAll?: () => void;
  onValueChange?: (rowId: number, colomnId: number, newValue: string) => void;
}

const VirtualTableFC = <T,>({
  isCheckboxShown,
  noItemsTitle,
  rows,
  columns,
  tableSx = {},
  variant,
  onSelect,
  onSelectAll,
  onUnselectAll,
  onValueChange = () => {}
}: IProps<T>) => {
  const [bgHex] = useToken('colors', ['bg']);

  const gridTemplate = useMemo(() => {
    return `${isCheckboxShown ? '20px' : ''} ${columns.map((column) => (column.width ? column.width : `minmax(90px , ${column.columnTemplate ?? '1fr'})`)).join(' ')}`;
  }, [columns, isCheckboxShown]);

  const isAllSelected = (): boolean => {
    return rows.every((row) => row.isDisabled || row.isSelected);
  };

  const isFirstColumnSticky = variant === TABLE_VARIANTS.FIRST_COLOMN_FIXED;

  const columnFixedPosition = isCheckboxShown ? '24px' : '0px';

  return (
    <Flex
      flexDirection="column"
      border="1px solid"
      borderColor="border"
      borderRadius="4px"
      w="100%"
      h="100%"
      sx={tableSx}
      overflow="hidden"
    >
      {rows.length > 0 && (
        <TableVirtuoso
          data={rows}
          style={{flexGrow: 1, overflowY: 'scroll', width: '100%'}}
          components={{
            Table: ({style, ...props}, _ref) => (
              <Table
                {...props}
                variant="unstyled"
                style={{
                  ...style,
                  width: '100%',
                  background: bgHex
                }}
              />
            ),
            TableHead: forwardRef(({style, ...props}, ref) => (
              <Thead {...props} style={style} ref={ref} />
            )),
            TableBody: forwardRef(({style, ...props}, ref) => (
              <Tbody {...props} style={{...style, padding: '2px 0'}} ref={ref} />
            )),
            TableRow: forwardRef(({style, ...props}, _ref) => (
              <Tr
                {...props}
                style={{...style}}
                border="none"
                display="grid"
                gridTemplateColumns={gridTemplate}
                alignItems="center"
                gap="4px"
              />
            ))
          }}
          fixedHeaderContent={() => (
            <Tr
              gap="4px"
              borderBottom="1px solid"
              borderColor="border"
              bg="bg"
              minH="36px"
              display="grid"
              alignItems="center"
              gridTemplateColumns={gridTemplate}
            >
              {isCheckboxShown && (
                <Th
                  pl="4px"
                  position={isFirstColumnSticky ? 'sticky' : 'static'}
                  left={isFirstColumnSticky ? '0' : undefined}
                >
                  {!!onSelectAll && !!onUnselectAll && (
                    <Checkbox
                      name="selectAll"
                      checked={isAllSelected()}
                      onChange={() => {
                        if (isAllSelected()) {
                          onUnselectAll();
                        } else {
                          onSelectAll();
                        }
                      }}
                    />
                  )}
                </Th>
              )}
              {columns.map((column, idx) => (
                <Th
                  key={column.name}
                  textAlign={column.align ?? 'center'}
                  align="center"
                  borderColor="border"
                  minH="24px"
                  bg="bg"
                  px="0px"
                  _last={{paddingRight: '4px'}}
                  _first={{
                    paddingLeft: '4px'
                  }}
                  style={
                    idx === 0 && isFirstColumnSticky
                      ? {
                          borderRight: '1px solid',
                          borderColor: 'inherit',
                          position: 'sticky',
                          left: columnFixedPosition,
                          zIndex: 1
                        }
                      : {}
                  }
                >
                  <Text
                    fontSize="12px"
                    color="bodyText"
                    style={
                      column.ellipsis
                        ? {
                            overflow: 'hidden',
                            whiteSpace: 'nowrap',
                            textOverflow: 'ellipsis'
                          }
                        : {}
                    }
                  >
                    {column.name} {!!column.unit && `(${column.unit})`}
                  </Text>
                </Th>
              ))}
            </Tr>
          )}
          itemContent={(rowindex, {key, values, isSelected, isDisabled}) => {
            return (
              <>
                {isCheckboxShown && (
                  <Td
                    p="0px 0px 0px 4px"
                    position={isFirstColumnSticky ? 'sticky' : 'static'}
                    left={isFirstColumnSticky ? '0' : undefined}
                  >
                    <Checkbox
                      name={`checkbox_${rowindex}`}
                      checked={isSelected}
                      isDisabled={isDisabled}
                      onChange={(checked) => {
                        onSelect?.(key, checked);
                      }}
                    />
                  </Td>
                )}

                {values.map((value, index) => (
                  <Td
                    key={index}
                    className="table-column"
                    align="center"
                    h="24px"
                    boxSizing="content-box"
                    overflow="hidden"
                    justifyContent={columns[index]?.align === 'right' ? 'end' : 'start'}
                    borderRight={isFirstColumnSticky && index === 0 ? '1px solid' : ''}
                    borderColor="border"
                    bg="bg"
                    p="2px 0"
                    position={index === 0 && isFirstColumnSticky ? 'sticky' : 'static'}
                    left={index === 0 && isFirstColumnSticky ? columnFixedPosition : undefined}
                    _last={{paddingRight: '4px'}}
                    _first={{
                      paddingLeft: '4px'
                    }}
                    opacity={columns[index].onlyOnHover ? 0 : undefined}
                    _groupHover={{opacity: columns[index].onlyOnHover ? 1 : undefined}}
                  >
                    {typeof columns[index].readOnly === 'boolean' && !columns[index].readOnly ? (
                      <TableEditableValue
                        isDisabled={!!isDisabled}
                        type={columns[index].type}
                        options={columns[index].options}
                        value={value}
                        onChangeValue={(newValue: string) => {
                          onValueChange(rowindex, index, newValue);
                        }}
                      />
                    ) : (
                      <TableValue
                        isDisabled={!!isDisabled}
                        value={value}
                        render={columns[index].render}
                      />
                    )}
                  </Td>
                ))}
              </>
            );
          }}
        />
      )}

      {rows.length === 0 && (
        <Center h="60px" fontSize="12px" color="bodyText">
          {noItemsTitle}
        </Center>
      )}
    </Flex>
  );
};

export const VirtualTable = VirtualTableFC;
