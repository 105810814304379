import {flow, Instance, types} from 'mobx-state-tree';

import {User as UserApi, UsersApi} from 'api';
import {RequestModel, ResetModel, User} from 'core/models';
import {UserManager, hasAuthParams} from 'core/services/oidc.service';

const AuthStore = types
  .compose(
    ResetModel,
    types.model('AuthStore', {
      isFailed: false,
      isAuthenticated: false,
      user: types.maybeNull(User),
      userInfoRequest: types.optional(RequestModel, {})
    })
  )
  .actions(() => ({
    _doSignIn: async () => {
      if (hasAuthParams()) {
        console.info('[Auth] Url has auth params.');
        await UserManager.signinCallback();
        window.history.replaceState({}, document.title, window.location.pathname);
      }

      UserManager.events.addSilentRenewError((error) => {
        console.info('[Auth] Silent renew error:', error);
        return UserManager.signinRedirect();
      });

      const oidcUser = await UserManager.getUser();
      if (!oidcUser || oidcUser.expired) {
        console.info('[Auth] Oidc user error, redirect to sign in.');
        await UserManager.signinRedirect();
      } else {
        console.info('[Auth] Success', oidcUser.profile?.name);
      }
    }
  }))
  .actions((self) => ({
    loadUser: flow(function* () {
      try {
        yield self._doSignIn();

        const user: UserApi = yield self.userInfoRequest.send(
          UsersApi.usersGetUserInfo.bind(UsersApi),
          {}
        );

        self.user = User.create({
          id: user.id,
          email: user.email || '',
          name: `${user.name}`
        });

        self.isAuthenticated = true;
      } catch (error) {
        self.isFailed = true;
        console.error('[Auth] Error:', error);
      }
    }),
    signOut: flow(function* () {
      yield UserManager.signoutRedirect();
      self.user = null;
      self.isAuthenticated = false;
    })
  }))
  .views((self) => ({
    get isUser(): boolean {
      return self.isAuthenticated && !!self.user;
    }
  }));

export type TAuthStore = Instance<typeof AuthStore>;

export {AuthStore};
