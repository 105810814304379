import {FC} from 'react';
import {observer} from 'mobx-react-lite';
import {Box, Text} from '@chakra-ui/react';
import {getDateTimeString} from '@progress-fe/core';

import {ILogPoint} from 'core/interfaces';

interface IProps {
  logPoints: ILogPoint[];
  activePoint: Date;
  onSelectPoint: (point: Date) => void;
}

const LogPointsFC: FC<IProps> = ({logPoints, activePoint, onSelectPoint}) => {
  return (
    <Box>
      {logPoints.map((logPoint) => (
        <Box
          key={logPoint.calculationNumber}
          p="2px 8px"
          bg={activePoint === logPoint.runAt ? 'buttonBg' : 'transparent'}
          _hover={{bg: 'buttonBg', cursor: 'pointer'}}
          onClick={() => onSelectPoint(logPoint.runAt)}
        >
          <Text as="span" fontSize={'12px'} fontWeight={500}>
            №{logPoint.calculationNumber}:{' '}
          </Text>
          {getDateTimeString(logPoint.runAt)}
        </Box>
      ))}
    </Box>
  );
};

export const LogPoints = observer(LogPointsFC);
