import {cast, flow, types} from 'mobx-state-tree';
import {v4 as uuidv4} from 'uuid';
import {delay, EElement, EStructureItem, EventEmitter} from '@progress-fe/core';
import {
  ERFEdge,
  IRFNodePort,
  RF_ENERGY_EDGE_PROPS,
  RF_MATERIAL_EDGE_PROPS,
  RFElementByElement,
  TRFEdgeDataConfig,
  TRFWorkZoneDataConfig
} from '@progress-fe/rf-core';
import {Edge, Node, XYPosition} from '@xyflow/react';

import {ELogItemType} from 'core/enums';
import {ILogPoint} from 'core/interfaces';
import {TechProcessGetTechprocessJournalOrderByEnum} from 'api/generated';
import {
  TechProcessApi,
  CallbackRunResponse,
  CalculationTaskRunStatus,
  TechProcessJournalTaskRunPoint,
  TechProcessJournalTaskRunPointEnum
} from 'api';
import {
  ProjectBase,
  TElementDetailsModel,
  TModelDetailsModel,
  TResultDetailsModel
} from 'core/models';

import {
  ProjectElements,
  ProjectModels,
  ProjectResults,
  ProjectSettings,
  ProjectTask
} from './models';

const RUN_DELAY_MS = 300;

const TechProcessStore = types
  .compose(
    ProjectBase,
    types.model('TechProcessStore', {
      projectSettings: types.optional(ProjectSettings, {}),
      projectModels: types.optional(ProjectModels, {}),
      projectElements: types.optional(ProjectElements, {}),
      projectTask: types.optional(ProjectTask, {}),
      projectResults: types.optional(ProjectResults, {})
    })
  )
  .volatile<{_runWaiter: NodeJS.Timeout | null; _runAttempt: number}>(() => ({
    _runWaiter: null,
    _runAttempt: 0
  }))
  .views((self) => ({
    get isFormsUpdating(): boolean {
      return self.projectElements.isFormDataUpdating || self.projectModels.isFormDataUpdating;
    }
  }))
  .actions((self) => ({
    _clearAllJsonSchemas(): void {
      self.projectModels.clearJsonSchemas();
      self.projectElements.clearJsonSchemas();
    },
    _reloadActiveEntity(): void {
      this._clearAllJsonSchemas();

      if (self.uiState.entityType === EStructureItem.Element) {
        self.projectElements.loadJsonSchemasByUuid(self.uiState.entityId).then();
      } else if (self.uiState.entityType === EStructureItem.Model) {
        self.projectModels.loadJsonSchemasByUuid(self.uiState.entityId).then();
      }
    },
    _selectEntityById(entityId: string): void {
      this._clearAllJsonSchemas();

      if (self.projectElements.hasElement(entityId)) {
        self.uiState.select(EStructureItem.Element, entityId);
        self.projectElements.loadJsonSchemasByUuid(entityId).then();
      } else if (self.projectModels.hasModel(entityId)) {
        self.uiState.select(EStructureItem.Model, entityId);
        self.projectModels.loadJsonSchemasByUuid(entityId).then();
      } else if (self.projectResults.hasResult(entityId)) {
        self.uiState.select(EStructureItem.Result, entityId);
      }
    },
    selectEntityByType(entityType: EStructureItem, entityId?: string | null) {
      this._clearAllJsonSchemas();
      self.uiState.select(entityType, entityId);

      if (entityType === EStructureItem.Element && !!entityId) {
        self.projectElements.loadJsonSchemasByUuid(entityId).then();
      } else if (entityType === EStructureItem.Model && !!entityId) {
        self.projectModels.loadJsonSchemasByUuid(entityId).then();
      }
    }
  }))
  .actions((self) => ({
    _subscribe() {
      EventEmitter.on('SelectItem', self._selectEntityById);
    },
    _unsubscribe() {
      EventEmitter.off('SelectItem', self._selectEntityById);
    },
    uninitialize() {
      this._unsubscribe();
      self.resetModel();
    }
  }))
  .actions((self) => ({
    runElementCallback: flow(function* (callbackCode: string) {
      console.info(`Run callback: code ${callbackCode}, element ${self.uiState.entityId}`);
      if (self.uiState.entityType !== EStructureItem.Element) {
        return null;
      }

      const response: CallbackRunResponse = yield self.runCallbackRequest.send(
        TechProcessApi.techProcessRunElementCallback.bind(TechProcessApi),
        {
          projectUuid: self.projectUuid,
          checkpointUuid: self.checkpointUuid,
          elementUuid: self.uiState.entityId,
          callbackRunRequest: {
            callbackCode
          }
        }
      );

      return response?.fieldValue || null;
    }),
    runModelCallback: flow(function* (callbackCode: string) {
      console.info(`Run callback: code ${callbackCode}, model ${self.uiState.entityId}`);
      if (self.uiState.entityType !== EStructureItem.Model) {
        return null;
      }

      const response: CallbackRunResponse = yield self.runCallbackRequest.send(
        TechProcessApi.techProcessRunModelCallback.bind(TechProcessApi),
        {
          projectUuid: self.projectUuid,
          checkpointUuid: self.checkpointUuid,
          modelInstanceUuid: self.uiState.entityId,
          callbackRunRequest: {
            callbackCode
          }
        }
      );

      // TODO: Processing...
      console.info('callback response', response?.fieldValue || null);
      return response?.fieldValue || null;
    })
  }))
  .actions((self) => ({
    createElement: flow(function* (type: EElement, templateCode?: string, position?: XYPosition) {
      const element = yield self.projectElements.add(type);
      if (!!element) {
        const node: Node<TRFWorkZoneDataConfig> = {
          id: element.uuid,
          type: RFElementByElement[type] || undefined,
          position: position || {x: 0, y: 0},
          data: {
            elementName: element.name,
            templateCode: templateCode || 'default'
          }
        };

        self.projectElements.nodes.push(node);
        self.projectElements.elements.push(element);
        self.selectEntityByType(EStructureItem.Element, element.uuid);
        yield self.projectElements.updateWorkZone();

        return element.uuid;
      } else {
        return null;
      }
    }),
    deleteElement: flow(function* (uuid: string) {
      const isDone = yield self.projectElements.remove(uuid);
      if (isDone) {
        const {nodes, elements} = self.projectElements;
        self.projectElements.nodes = cast([...nodes.filter((n) => n.id !== uuid)]);
        self.projectElements.elements = cast([...elements.filter((n) => n.uuid !== uuid)]);
        self.selectEntityByType(EStructureItem.Settings);
        yield self.projectElements.updateWorkZone();
      }
    }),
    connectElements: flow(function* (from: IRFNodePort, to: IRFNodePort, type: ERFEdge) {
      const isDone = yield self.projectElements.connect(from, to);
      if (isDone) {
        const edge: Edge<TRFEdgeDataConfig> = {
          id: uuidv4(),
          type: type,
          source: from.nodeUuid,
          target: to.nodeUuid,
          sourceHandle: from.portCode,
          targetHandle: to.portCode,
          reconnectable: true,
          deletable: true,
          ...(type === ERFEdge.Energy ? RF_ENERGY_EDGE_PROPS : RF_MATERIAL_EDGE_PROPS)
        };

        const selectedUuid = self.uiState.entityId === from.nodeUuid ? to.nodeUuid : from.nodeUuid;
        self.projectElements.edges.push(edge);
        self.selectEntityByType(EStructureItem.Element, selectedUuid);
        yield self.projectElements.updateWorkZone();
      }
    }),
    disconnectElements: flow(function* (from: IRFNodePort, to: IRFNodePort, id: string) {
      const isDone = yield self.projectElements.disconnect(from, to);
      if (isDone) {
        const {edges} = self.projectElements;
        const selectedUuid = self.uiState.entityId === from.nodeUuid ? to.nodeUuid : from.nodeUuid;

        self.projectElements.edges = cast([...edges.filter((n) => n.id !== id)]);
        self.selectEntityByType(EStructureItem.Element, selectedUuid);
        yield self.projectElements.updateWorkZone();
      }
    }),
    createModel: flow(function* (uuid: string) {
      const model = yield self.projectModels.addModel(uuid);
      if (!!model) {
        self.projectModels.models.push(model);
        self.selectEntityByType(EStructureItem.Model, model.uuid);
      }
    }),
    deleteModel: flow(function* (uuid: string) {
      const isDone = yield self.projectModels.removeModel(uuid);
      if (isDone) {
        const {models} = self.projectModels;
        self.projectModels.models = cast([...models.filter((n) => n.uuid !== uuid)]);
        self.selectEntityByType(EStructureItem.Settings);
      }
    })
  }))
  .actions((self) => ({
    _loadLogs: flow(function* () {
      const response: TechProcessJournalTaskRunPoint[] = yield self.logsRequest.send(
        TechProcessApi.techProcessGetTechprocessJournal.bind(TechProcessApi),
        {
          projectUuid: self.projectUuid,
          checkpointUuid: self.checkpointUuid,
          orderByField: TechProcessJournalTaskRunPointEnum.RunAt,
          orderBy: TechProcessGetTechprocessJournalOrderByEnum.Desc
        }
      );

      if (response?.length) {
        const logs: ILogPoint[] = response.map((point) => ({
          runAt: point.runAt,
          calculationNumber: point.calculationNumber,
          items: point.items.map((item) => ({
            type: item.type as ELogItemType,
            createdAt: item.createdAt,
            message: item.message
          }))
        }));

        self.logger.setPoints(logs);
      } else {
        self.logger.setPoints([]);
      }
    })
  }))
  .actions((self) => ({
    _executeRun: flow(function* () {
      if (self.runRequest.isPending) return;

      console.info('[Run] In progress...');
      const response: CalculationTaskRunStatus = yield self.runRequest.send(
        TechProcessApi.techProcessRunTechprocessCalculationTask.bind(TechProcessApi),
        {
          projectUuid: self.projectUuid,
          checkpointUuid: self.checkpointUuid
        }
      );

      if (!!response?.success) {
        self.errors = cast([]);
        self._reloadActiveEntity();
      } else {
        self.errors = cast([{title: 'Запуск', message: 'Неизвестная ошибка'}]);
      }

      yield self._loadLogs();

      console.info('[Run] Finished.');
      self.isRunning = false;
    })
  }))
  .actions((self) => {
    const actions = {
      _tryRun: flow(function* () {
        self._runAttempt++;
        console.info(`[Run] Start attempt ${self._runAttempt}.`);
        if (!self.isFormsUpdating) {
          if (self._runWaiter) {
            clearInterval(self._runWaiter);
            self._runAttempt = 0;
          }
          yield self._executeRun();
        }
      }),
      run: flow(function* () {
        self.isRunning = true;

        // TODO: Fix waiting for living focus
        yield delay(RUN_DELAY_MS);

        self._runWaiter = setInterval(() => {
          actions._tryRun().then();
        }, RUN_DELAY_MS);
      })
    };
    return actions;
  })
  .actions((self) => ({
    initProject: flow(function* (projectId: string) {
      self.isLoading = true;
      yield self._baseInit(projectId);

      if (!!self.projectInfo) {
        const {uuid} = self.projectInfo;

        self.projectSettings.init(projectId); // TODO
        yield self.projectModels.init(uuid, self.checkpointUuid);
        yield self.projectElements.init(uuid, self.checkpointUuid);
        yield self.projectTask.init(uuid, self.checkpointUuid);
        self.projectResults.fetch(projectId); // TODO

        yield self._loadLogs();
        self._subscribe();
      }

      self.isLoading = false;
    })
  }))
  .views((self) => ({
    get selectedElement(): TElementDetailsModel | null {
      if (self.uiState.entityType !== EStructureItem.Element) return null;
      return self.projectElements.elements.find((el) => el.uuid === self.uiState.entityId) || null;
    },
    get selectedModel(): TModelDetailsModel | null {
      if (self.uiState.entityType !== EStructureItem.Model) return null;
      return self.projectModels.models.find((el) => el.uuid === self.uiState.entityId) || null;
    },
    get selectedResult(): TResultDetailsModel | null {
      if (self.uiState.entityType !== EStructureItem.Result) return null;
      return self.projectResults.results.find((el) => el.id === self.uiState.entityId) || null;
    }
  }));

export {TechProcessStore};
