import {FC, useCallback} from 'react';
import {observer} from 'mobx-react-lite';
import {Box, Center, Flex, Grid, Text} from '@chakra-ui/react';
import {useNavigate} from 'react-router';
import {getTimeAgo, useT} from '@progress-fe/core';
import {Loader, Svg} from '@progress-fe/ui-kit';

import {ProjectOut} from 'api';
import {ProjectTypeTag} from 'ui-kit';
import {getProjectUrl} from 'core/utils';
import {EProjectSortField, EProjectSortType} from 'core/enums';
import {TProjectsSortsModel} from 'stores/MyProjectsStore/models';

interface IProps {
  isLoading: boolean;
  projects: ProjectOut[];
  createdBy: string; // FIXME: Should be a part of ProjectOut
  activeSort: TProjectsSortsModel;
  onUpdateActiveSort: (orderBy: EProjectSortType, orderByField: EProjectSortField) => void;
}

interface IListColumn {
  title: string;
  sortField?: EProjectSortField | null;
}

const ProjectListFC: FC<IProps> = ({
  isLoading,
  projects,
  createdBy,
  activeSort,
  onUpdateActiveSort
}) => {
  const navigate = useNavigate();

  const {t} = useT();

  const columns: IListColumn[] = [
    {title: t('fields.name'), sortField: EProjectSortField.Name},
    {title: t('fields.category'), sortField: EProjectSortField.Type},
    {title: t('fields.updatedAt'), sortField: EProjectSortField.UpdatedAt},
    {title: t('fields.createdAt'), sortField: EProjectSortField.CreatedAt},
    {title: t('fields.activity')}
  ];

  const isActiveSortAsc = activeSort.orderBy === EProjectSortType.Asc;

  const handleUpdateSort = useCallback(
    (field?: EProjectSortField | null) => {
      if (!!field) {
        const isAscActive = activeSort.orderBy === EProjectSortType.Asc;
        const newSortType = isAscActive ? EProjectSortType.Desc : EProjectSortType.Asc;
        onUpdateActiveSort(newSortType, field);
      }
    },
    [activeSort, onUpdateActiveSort]
  );

  const isColumnHasSort = useCallback(
    (field?: EProjectSortField | null) => {
      return !!activeSort.orderByField && activeSort.orderByField === field;
    },
    [activeSort]
  );

  const gridGap = '10px';
  const gridTemplate = '1fr 150px 160px 160px 160px';

  return (
    <Box w="100%">
      <Grid gap="12px" gridTemplateColumns={gridTemplate} p="0 24px">
        {columns.map((column) => {
          return (
            <Flex
              h="48px"
              gap={gridGap}
              align="center"
              key={column.title}
              _hover={{cursor: 'pointer'}}
              onClick={() => handleUpdateSort(column.sortField)}
            >
              <Text fontWeight="600" fontSize="14px">
                {column.title}
              </Text>
              {isColumnHasSort(column.sortField) && (
                <Box transform={isActiveSortAsc ? 'rotate(0deg)' : 'rotate(180deg)'}>
                  <Svg name={'ArrowDown'} />
                </Box>
              )}
            </Flex>
          );
        })}
      </Grid>

      {isLoading ? (
        <Center pt="150px">
          <Loader />
        </Center>
      ) : (
        <>
          <Box overflowY="auto" maxHeight="calc(var(--height-layout) - 220px)">
            {projects.map(({uuid, type, name, createdAt, updatedAt}) => (
              <Grid
                key={name}
                p="0 24px"
                bg="white"
                gap={gridGap}
                borderBottom="1px solid"
                borderColor="border"
                gridTemplateColumns={gridTemplate}
                onClick={() => navigate(getProjectUrl(type, uuid))}
                _last={{borderBottom: 'none'}}
                _hover={{opacity: '0.6', cursor: 'pointer'}}
              >
                <Flex align="center" h="64px" overflow="hidden">
                  <Text fontWeight="600" fontSize="14px" className="cut-text">
                    {name}
                  </Text>
                </Flex>
                <Flex align="center" h="64px">
                  <ProjectTypeTag type={type} />
                </Flex>
                <Flex align="center" h="64px">
                  <Text fontSize="14px">{getTimeAgo(updatedAt.toISOString())}</Text>
                </Flex>
                <Flex align="center" h="64px">
                  <Text fontSize="14px">{getTimeAgo(createdAt.toISOString())}</Text>
                </Flex>
                <Flex align="center" h="64px">
                  <Text fontSize="14px">{createdBy}</Text>
                </Flex>
              </Grid>
            ))}
          </Box>

          {projects.length === 0 && (
            <Center textAlign="center" h="64px" bg="white" fontSize="14px">
              {t('projects.notFound')}
            </Center>
          )}
        </>
      )}
    </Box>
  );
};

export const ProjectList = observer(ProjectListFC);
