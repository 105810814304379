import {cast, Instance, types} from 'mobx-state-tree';

import {ResetModel} from 'core/models';

import {ChemicalComponents} from './models';

const ProjectSettings = types
  .compose(
    ResetModel,
    types.model('ProjectSettings', {
      chemicalComponents: types.optional(ChemicalComponents, {}),
      favoritesComponents: types.optional(types.array(types.string), [])
    })
  )
  .actions((self) => ({
    init(projectId: string): void {
      console.info(projectId);
    },
    addToFavorites(componentsIds: string[]): void {
      const newFavorites = [...self.favoritesComponents.concat(componentsIds)];
      self.favoritesComponents = cast(newFavorites);
    },
    removeFromFavorites(componentsIds: string[]): void {
      const removeSet = new Set(componentsIds);
      const filtered = self.favoritesComponents.filter((item) => !removeSet.has(item));
      self.favoritesComponents = cast(filtered);
    }
  }))
  .views((self) => ({
    get favorites(): string[] {
      return self.favoritesComponents;
    }
  }));

export type TProjectSettingsModel = Instance<typeof ProjectSettings>;

export {ProjectSettings};
