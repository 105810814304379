import {FC} from 'react';
import {Box, Center} from '@chakra-ui/react';
import {observer} from 'mobx-react-lite';
import {JsFormName, Loader} from '@progress-fe/ui-kit';

import {TModelDetailsModel, TJsonSchemaModel} from 'core/models';
import {IJsonSchemaTab, JsTabsFormWrapper} from 'ui-kit';

interface IProps {
  modelDetails: TModelDetailsModel;
  onUpdateFormData: (uuid: string, jsonSchema: string, formData: unknown) => Promise<void>;
}

const ModelFormsFC: FC<IProps> = ({modelDetails, onUpdateFormData}) => {
  const {name, jsonSchemas, isSchemasLoading} = modelDetails;

  const tabs: IJsonSchemaTab[] = jsonSchemas.map((jsonSchema, index) => ({
    index: index,
    name: jsonSchema.name,
    isDisabled: jsonSchema.schema.readOnly,
    jsonSchema
  }));

  const handleChangeFormData = (formData: unknown, jsonSchema: TJsonSchemaModel) => {
    onUpdateFormData(modelDetails.uuid, jsonSchema.id, formData).then();
  };

  return (
    <Box>
      <JsFormName name={name} />

      {isSchemasLoading ? (
        <Center p="100px 0 0">
          <Loader />
        </Center>
      ) : (
        <JsTabsFormWrapper
          tabs={tabs}
          onChange={(formData, tabId: number) => {
            handleChangeFormData(formData, tabs[tabId]?.jsonSchema);
          }}
        />
      )}
    </Box>
  );
};

export const ModelForms = observer(ModelFormsFC);
