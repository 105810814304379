import {flow, Instance, types} from 'mobx-state-tree';

import {ETaskType} from 'core/enums';
import {RequestModel, ResetModel} from 'core/models';
import {TechProcessApi, TechprocessCalculationTaskOut} from 'api';

const ProjectTask = types
  .compose(
    ResetModel,
    types.model('ProjectTask', {
      projectUuid: '',
      checkpointUuid: '',
      type: types.optional(types.enumeration(Object.values(ETaskType)), ETaskType.Base),
      description: types.optional(types.string, ''),
      fetchRequest: types.optional(RequestModel, {})
    })
  )
  .actions((self) => ({
    _loadTaskDetails: flow(function* () {
      const response: TechprocessCalculationTaskOut = yield self.fetchRequest.send(
        TechProcessApi.techProcessGetTechprocessCalculationTasks.bind(TechProcessApi),
        {
          projectUuid: self.projectUuid,
          checkpointUuid: self.checkpointUuid
        }
      );
      self.description = response?.description || '';
    }),
    _updateDescription: flow(function* () {
      yield self.fetchRequest.send(
        TechProcessApi.techProcessSetTechprocessCalculationTask.bind(TechProcessApi),
        {
          projectUuid: self.projectUuid,
          checkpointUuid: self.checkpointUuid,
          techprocessCalculationTaskUpdate: {
            type: self.type,
            description: self.description
          }
        }
      );
    })
  }))
  .actions((self) => ({
    init: flow(function* (projectUuid: string, checkpointUuid: string) {
      self.resetModel();
      self.projectUuid = projectUuid;
      self.checkpointUuid = checkpointUuid;

      yield self._loadTaskDetails();
    })
  }))
  .actions((self) => ({
    setType(type: ETaskType): void {
      self.type = type;
    },
    setDescription: flow(function* (description: string) {
      self.description = description;
      yield self._updateDescription();
    })
  }))
  .views(() => ({}));

export type TProjectTaskModel = Instance<typeof ProjectTask>;

export {ProjectTask};
