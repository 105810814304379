import {FC, useCallback} from 'react';
import {observer} from 'mobx-react-lite';
import {Box, Center, Text} from '@chakra-ui/react';
import {getDateTimeString, useT} from '@progress-fe/core';

import {ILogItem} from 'core/interfaces';
import {ELogItemType} from 'core/enums';

interface IProps {
  items: ILogItem[];
}

const LogItemsFC: FC<IProps> = ({items}) => {
  const {t} = useT();

  const getColor = useCallback((type: ELogItemType) => {
    switch (type) {
      case ELogItemType.Error:
        return 'darkRed';
      case ELogItemType.Warning:
        return 'darkOrange';
      case ELogItemType.Success:
        return 'success';
      default:
        return 'black';
    }
  }, []);

  if (!items.length) {
    return (
      <Center fontFamily="ptmono" h={'100%'}>
        {t('common.logsEmpty')}
      </Center>
    );
  }

  return (
    <Box p={'6px 6px 2px 6px'} fontFamily="ptmono">
      {items.map((item, i) => (
        <Box key={i} fontSize={'12px'} lineHeight={'16px'} pb={'2px'} color={getColor(item.type)}>
          <Text as="span" fontSize={'12px'} fontWeight={600} color={'inherit'}>
            {getDateTimeString(item.createdAt)}:{' '}
          </Text>
          {item.message}
        </Box>
      ))}
    </Box>
  );
};

export const LogItems = observer(LogItemsFC);
