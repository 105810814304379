import {FC} from 'react';
import {Box, Center} from '@chakra-ui/react';
import {observer} from 'mobx-react-lite';
import {JsFormName, Loader} from '@progress-fe/ui-kit';

import {IJsonSchemaTab, JsTabsFormWrapper} from 'ui-kit';
import {TElementDetailsModel, TJsonSchemaModel} from 'core/models';

interface IProps {
  elementDetails: TElementDetailsModel;
  onUpdateFormData: (uuid: string, jsonSchema: string, formData: unknown) => Promise<void>;
}

const ElementFormsFC: FC<IProps> = ({elementDetails, onUpdateFormData}) => {
  const {name, jsonSchemas, isSchemasLoading} = elementDetails;

  const tabs: IJsonSchemaTab[] = jsonSchemas.map((jsonSchema, index) => ({
    index: index,
    name: jsonSchema.name,
    jsonSchema
  }));

  const handleChangeFormData = (formData: unknown, jsonSchema: TJsonSchemaModel) => {
    onUpdateFormData(elementDetails.uuid, jsonSchema.id, formData).then();
  };

  return (
    <Box>
      <JsFormName name={name} />

      {isSchemasLoading ? (
        <Center p="100px 0 0">
          <Loader />
        </Center>
      ) : (
        <JsTabsFormWrapper
          tabs={tabs}
          onChange={(formData, tabId: number) => {
            handleChangeFormData(formData, tabs[tabId]?.jsonSchema);
          }}
        />
      )}
    </Box>
  );
};

export const ElementForms = observer(ElementFormsFC);
