import {FC} from 'react';
import {observer} from 'mobx-react-lite';
import {SystemStyleObject} from '@chakra-ui/styled-system';
import {VirtualTable, ISimpleTableColumn, ISimpleTableRow} from '@progress-fe/ui-kit';
import {useT, IPureComponent, EComponentBaseType} from '@progress-fe/core';

interface IProps {
  pureComponents: IPureComponent[];
  tableSx?: SystemStyleObject;
}

const SelectedComponentsFC: FC<IProps> = ({pureComponents, tableSx}) => {
  const {t} = useT();

  const columns: ISimpleTableColumn[] = [{name: 'Название'}, {name: 'Тип'}, {name: 'Класс'}];

  const pureRows: ISimpleTableRow<string>[] = pureComponents.map((component) => {
    return {
      key: component.uuid,
      values: [
        component.name,
        t(`enum.componentBaseType.${EComponentBaseType.PURE}`),
        component.classType ? t(`enum.pureChemicalComponentClass.${component.classType}`) : '—'
      ]
    };
  });

  return (
    <VirtualTable
      columns={columns}
      rows={pureRows}
      noItemsTitle="Нет компонентов"
      tableSx={tableSx}
    />
  );
};

export const SelectedComponents = observer(SelectedComponentsFC);
