import {flow, types} from 'mobx-state-tree';
import {EventEmitter} from '@progress-fe/core';

import {ProjectBase, TProjectEntityJsonSchemasModel} from 'core/models';

import {ProjectProbes, ProjectBlends} from './models';

const SamplesAndOilStore = types
  .compose(
    ProjectBase,
    types.model('SamplesAndOilProject', {
      projectProbes: types.optional(ProjectProbes, {}),
      projectBlends: types.optional(ProjectBlends, {})
    })
  )
  .actions(() => ({
    _selectEntity() {
      throw Error('there is no implementation');
    }
  }))
  .actions((self) => ({
    subscribe() {
      EventEmitter.on('SelectItem', self._selectEntity);
    },
    unsubscribe() {
      EventEmitter.off('SelectItem', self._selectEntity);
    },
    uninitialize() {
      this.unsubscribe();
      self.resetModel();
    }
  }))
  .actions((self) => ({
    initProject: flow(function* (projectId: string) {
      self.isLoading = true;
      yield self._baseInit(projectId);

      if (!!self.projectInfo) {
        self.projectProbes.fetch(projectId);
        self.projectBlends.fetch(projectId);
      }

      self.isLoading = false;
    })
  }))
  .actions(() => ({
    run: flow(function* () {
      // TODO: See techprocess store
      return Promise.resolve();
    })
  }))
  .views((self) => ({
    get selectedProbe(): TProjectEntityJsonSchemasModel | null {
      return self.projectProbes.probes.find((el) => el.id === self.uiState.entityId) || null;
    },

    get selectedBlend(): TProjectEntityJsonSchemasModel | null {
      return self.projectBlends.blends.find((el) => el.id === self.uiState.entityId) || null;
    }
  }));

export {SamplesAndOilStore};
