import {FC, useState} from 'react';
import {observer} from 'mobx-react-lite';
import {Grid, IconButton} from '@chakra-ui/react';
import {SystemStyleObject} from '@chakra-ui/styled-system';
import {EPureChemicalComponentClass, IComponent} from '@progress-fe/core';

import {Svg} from '../../../../helpers';
import {VirtualTable} from '../../../../tables';
import {Input, Select} from '../../../../inputs';
import {ISelectOption, ISimpleTableColumn, ISimpleTableRow} from '../../../../../interfaces';

interface IProps {
  favorites: Set<string>;
  components: IComponent[];
  selectedComponents: Set<string>;
  onAddComponent: (value: string) => void;
  onDeleteComponent: (value: string) => void;
  onToggleFavorite?: (componentId: string) => void;
  onAddAllComponents?: () => void;
  onDeleteAllComponents?: () => void;
  tableSx?: SystemStyleObject;
}

const PureComponentsFC: FC<IProps> = ({
  favorites,
  components,
  selectedComponents,
  onAddComponent,
  onDeleteComponent,
  onAddAllComponents,
  onDeleteAllComponents,
  onToggleFavorite,
  tableSx
}) => {
  const [query, setQuery] = useState('');
  const [selectedClassType, setSelectedClassType] = useState(EPureChemicalComponentClass.ALL);

  const pureChemicalClassTypeOptions: ISelectOption<EPureChemicalComponentClass>[] = [
    {value: EPureChemicalComponentClass.ALL, label: 'Все классы'},
    {value: EPureChemicalComponentClass.HYDROCARBONS, label: 'Углеводороды'},
    {value: EPureChemicalComponentClass.NON_ORGANIC, label: 'Неогранические'},
    {value: EPureChemicalComponentClass.SULFUR_CONTAINING, label: 'Серосодержащие'},
    {value: EPureChemicalComponentClass.NITROGEN_CONTAINING, label: 'Азотсодержащие'},
    {value: EPureChemicalComponentClass.HALOGENS, label: 'Галогены'},
    {value: EPureChemicalComponentClass.ALCOHOLS, label: 'Спирты'},
    {value: EPureChemicalComponentClass.KETONES, label: 'Кетоны'},
    {value: EPureChemicalComponentClass.ALDEHYDES, label: 'Альдегиды'},
    {value: EPureChemicalComponentClass.HYDROCARBON_ACIDS, label: 'Углевод. кистоты'},
    {value: EPureChemicalComponentClass.PHENOLS, label: 'Фенолы'}
  ];

  const columns: ISimpleTableColumn[] = [
    {name: 'Название'},
    {name: 'Формула'},
    {name: 'Структура'},
    {
      name: '',
      width: '24px',
      columnTemplate: '24px',
      onlyOnHover: true,
      render: (id: string) => {
        return (
          onToggleFavorite && (
            <IconButton
              size="2sm"
              aria-label=""
              variant="transparent"
              icon={<Svg size="s12" name={favorites.has(id) ? 'FavoriteOn' : 'Favorite'} />}
              onClick={() => onToggleFavorite(id)}
            />
          )
        );
      }
    }
  ];

  const filteredOptionsByName = components.filter(
    (opt) =>
      opt.name.toLowerCase().includes(query.toLowerCase()) ||
      opt.formula?.toLowerCase().includes(query.toLowerCase())
  );

  const filteredOptionsByNameAndClass = filteredOptionsByName.filter(
    (opt) =>
      selectedClassType === EPureChemicalComponentClass.ALL || selectedClassType === opt.classType
  );

  const rows: ISimpleTableRow<string>[] = filteredOptionsByNameAndClass.map((opt) => ({
    key: opt.uuid,
    values: [opt.name, opt.formula || '', opt.structure || '', opt.uuid],
    isSelected: selectedComponents.has(opt.uuid),
    isDisabled: false
  }));

  return (
    <Grid templateRows="auto 1fr" gap="8px">
      <Grid gridTemplateColumns="1fr 140px" gap="8px">
        <Input
          name="search"
          size="xs"
          variant="outline"
          value={query}
          placeholder="Поиск"
          onChange={setQuery}
        />
        <Select
          name="chemical_class"
          options={pureChemicalClassTypeOptions}
          value={pureChemicalClassTypeOptions.find((opt) => opt.value === selectedClassType)}
          onChange={(option) =>
            setSelectedClassType((option as ISelectOption<EPureChemicalComponentClass>).value)
          }
        />
      </Grid>

      <VirtualTable
        rows={rows}
        isCheckboxShown
        columns={columns}
        noItemsTitle="Компоненты не найдены"
        tableSx={tableSx}
        onSelectAll={onAddAllComponents}
        onUnselectAll={onDeleteAllComponents}
        onSelect={(value: string, isSelected: boolean) => {
          if (isSelected) {
            onAddComponent(value);
          } else {
            onDeleteComponent(value);
          }
        }}
      />
    </Grid>
  );
};

export const PureComponents = observer(PureComponentsFC);
